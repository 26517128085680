.home {
    width: 100%;
    top: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.landing-section {
    height: 100vh;
    width: 100%;
}

.head-text-container {
    padding-top: 15%;
}

.head-text {
    cursor: default;
    margin-left: 15%;
    width: max-content;
}

.head-text>h1 {
    font-size: 3.6rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.head-tagline {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.tag-word-rotation {
    position: absolute;
    display: inline-block;
    font-weight: 600;
    opacity: 0;
    transition: opacity 0.2s, transform 0.2s;
}

.tag-word-rotation.show {
    transform: scale(1.05);
    opacity: 1;
    animation: rotateTagWords 12s linear infinite;
}

@keyframes rotateTagWords {

    0%,
    100% {
        opacity: 0;
        transform: scale(1);
    }

    10%,
    20% {
        opacity: 1;
        transform: scale(1.05);
    }

    60%,
    90% {
        opacity: 0;
    }
}

.down-arrow-container {
    position: absolute;
    bottom: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

@keyframes scale-in {
    0% {
        transform: scale(1.0);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1.0);
    }
}

.svg-button {
    background-color: transparent;
    border: none;
    outline: none;
    transition: transform 0.3s alternate infinite;
    animation: scale-in 2.2s alternate infinite;
}

#down-arrow {
    color: var(--light-primary);
    transform: scale(1.9);
    cursor: pointer;
}

.all-blogs {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
}

.home::-webkit-scrollbar {
    display: none;
}

.all-blogs::-webkit-scrollbar {
    display: none;
}

.blogs-header {
    text-align: center;
    margin: 5rem auto 2rem auto;
}

.blogs-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 98%;
    gap: 1.8rem;
    margin: 0 auto;
    padding-top: 1rem;
    overflow-y: auto;
}

.top-btn-container {
    width: 100%;
    padding-top: 0;
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}

#home-top-scroll {
    color: var(--light-primary);
    margin-top: 0;
    margin-bottom: 2rem;
    transition: all 1.3s ease-in-out !important;
    border: 0.1rem solid var(--light-primary);
}

.blogs-container::-webkit-scrollbar {
    width: 0.3rem;
}

.blogs-container::-webkit-scrollbar-track {
    background: var(--dark-bg);
    border-radius: 1rem;
}

.blogs-container::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 1rem;
}

.blogs-container::-webkit-scrollbar-thumb:hover {
    background: #e6e6fa;
}

@media screen and (max-width: 600px) {
    .landing-section {
        height: 80vh;
        overflow-x: hidden;
    }

    .head-text-container {
        padding-top: 50%;
    }

    .head-text {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .all-blogs {
        width: 100%;
    }

    .head-text>h1 {
        font-size: 2.3rem;
        font-weight: 450;
    }

    .head-tagline {
        margin-left: -66px;
        font-size: 1.25rem;
        font-weight: 400;
    }

    .blogs-container {
        width: 100%;
        height: 100%;
        gap: 1.2rem;
        padding-bottom: 3rem;
    }

    .blogs-container::-webkit-scrollbar {
        display: none;
    }

    .blogs-header {
        margin-top: 4.8rem;
    }

    #top-scroll {
        margin: 0.2rem 0 3.2rem 0;
    }
}