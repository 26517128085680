@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700&display=swap');

@font-face {
  font-family: 'Ringbearer';
  src: url('../public/ringbearer-font/ring-bearer.ttf') format('ttf'),
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.app-content {
  min-height: 100vh;
}

:root {
  --light-primary: #000;
  --light-bg: #fff;
  --dark-primary: #fff;
  --dark-bg: #000;
}

body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Ubuntu', 'Source Sans Pro', sans-serif !important;
  color: var(--light-primary);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  scroll-behavior: smooth;
  overflow-x: hidden;
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background: var(--dark-bg);
  border-radius: 15px;
  transition: 0.3s;
}