.reading-progress-bar {
    position: fixed;
    top: 0;
    height: 4px;
    background: #CB356B;
    background: -webkit-linear-gradient(to right, #e25748, #CB356B);
    background: linear-gradient(to right, #e25748, #CB356B);
    z-index: 1000;
}

.blog-container {
    bottom: 0;
    width: 100%;
    cursor: default;
    overflow-y: auto;
    font-size: 1.2rem;
    font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
}

.post-container {
    padding: 2.5rem 20rem;
    color: rgb(32, 32, 32);
}

.post-container>h1 {
    color: #000;
    font-size: 2.6rem;
    font-weight: 500;
}

.blog-container img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
}

.post>p {
    margin-bottom: 1.6rem;
}

.blog-container a {
    color: #E52B50;
    text-decoration: none;
}

.blog-container a:visited {
    color: #E52B50;
}

.blog-container .img-caption {
    font-size: 1rem;
    font-weight: 400;
    margin: 0.4rem auto 1rem auto;
    text-align: center;
    color: #666;
}

.blog-page-meta {
    margin-bottom: 1.6rem;
}

.blog-page-meta>span {
    font-size: 0.9rem;
}

@media screen and (max-width: 768px) {
    .blog-container {
        font-size: 1.1rem;
    }

    .post-container {
        padding: 1.2rem;
    }

    .post-container>h1 {
        font-size: 1.8rem;
    }

    .post>p {
        margin-bottom: 1.8rem;
    }

    .blog-container img {
        max-width: 100%;
    }

    .blog-container .img-caption {
        font-size: 0.9rem;
    }
}