.poems-page-container {
    color: var(--light-primary);
}

.search-bar-container {
    display: flex;
    justify-content: right;
}

.search-bar {
    width: 16rem;
    padding: 0.5rem;
    border: 0.1rem solid var(--light-primary);
    border-radius: 0.5rem;
    color: var(--light-primary);
    background-color: var(--light-bg);
}

.poems-container {
    padding: 0.8rem;
}

.card-list {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 0.8rem;
    -moz-column-gap: 0.8rem;
    column-gap: 0.8rem;
    padding-top: 2rem;
}

#poems-top-scroll {
    color: var(--light-primary);
    margin-top: 0;
    margin-bottom: 2rem;
    transition: all 1.3s ease-in-out !important;
    border: 0.1rem solid var(--light-primary);
}

@media screen and (max-width: 1200px) {
    .card-list {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
}

@media screen and (max-width: 900px) {
    .card-list {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media screen and (max-width: 600px) {
    .card-list {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
}

@media (max-width: 768px) {
    .search-bar {
        width: 12rem;
    }

    .poems-container {
        padding: 1rem;
    }
}