.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;
  margin: auto;
  margin-right: 2.5rem;
  flex: 1;
  width: 100%;
  text-align: left;
}

.nav-bar {
  padding: 0;
  z-index: 9999;
  background-color: var(--dark-bg);
  transition: top 0.4s ease-in-out
}

.nav-hidden {
  top: -80px;
}

.nav-visible {
  top: 0;
}

.logo {
  width: 4.5rem;
  padding: 0;
  scale: 2.5;
}

.nav-link {
  font-size: 1.35rem;
  font-weight: 400;
  color: var(--dark-primary) !important;
  margin-right: 15px;
  text-decoration: none;
}

.nav-theme-toggle {
  display: none;
  margin-right: 1rem;
  margin-left: -2.5rem;
}

.toggle-btn {
  background: transparent;
  border: none;
}

#toggle-off {
  width: 1.8rem;
  height: 1.8rem;
  color: #1F1B24;
  display: none;
}

#toggle-on {
  width: 1.8rem;
  height: 1.8rem;
  color: #FFDF00;
}

@media screen and (max-width: 600px) {
  .nav-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-items: right;
    text-align: center;
    flex: 1;
    width: 100%;
  }

  .logo {
    top: 1rem;
    width: 4rem;
    padding: 0;
  }

  .nav-toggle {
    position: fixed;
    top: 1rem;
    right: 1rem;
    background-color: var(--dark-bg);
  }

  .nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0);
    border-radius: 0.5rem;
  }

  .nav-hidden,
  .nav-visible {
    top: 0;
  }

  .nav-bar {
    padding: 0.5rem;
    background-color: transparent;
  }

  .nav-link {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 400;
    text-decoration: none;
    transition: 0.15s;
  }

  .nav-theme-toggle {
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 4.6rem;
    display: none;
  }
}