.blog-card {
    width: 38rem;
    cursor: pointer;
    border-radius: 0.5rem;
    border: 0.1rem solid var(--light-primary);
}

.card-link,
.card-link:hover {
    text-decoration: none;
    color: var(--light-primary);
}

.card-content {
    padding: 1.2rem 1rem;
}

.card-title {
    text-align: left;
    margin-top: 0.5rem;
    padding: 0 1rem;
}

.blog-title {
    font-weight: 500;
    margin-bottom: 0.2rem;
}

.blog-meta {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 1rem;
}

.blog-date,
.blog-reading-time {
    font-size: 0.88rem;
    color: #514e4e;
    font-weight: 400;
    text-align: center;
    font-style: italic;
}

.card-description {
    margin: 0 auto;
    padding: 0.8rem;
}

.card-description-text {
    font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
}

.read-more {
    color: #514e4e;
    font-size: 1rem;
    padding: 0;
    border: none;
    transition: 0.1s;
    margin: 0;
    margin-top: 1rem;
    transition: 0.3s;
}

.read-more:hover {
    color: var(--light-primary);
}

@media screen and (max-width: 768px) {
    .blog-card {
        width: 92%;
        height: auto;
        margin: 0 auto;
    }

    .card-content {
        padding: 1.2rem 0.5rem;
    }

    .card-title {
        font-size: 0.2rem;
    }

    .blog-date {
        font-size: 0.8rem;
    }

    .card-description {
        font-size: 0.96rem;
    }

    .read-more {
        margin-top: 0.6rem;
    }
}