.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.8rem;
    padding-top: 8rem;
}

.not-found>h4 {
    font-size: 2.4rem;
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    .not-found>h4 {
        font-size: 1.6rem;
        font-weight: 500;
    }
}