.books-container {
    padding-bottom: 8rem;
    overflow: auto;
}

.bookshelf {
    display: flex;
    flex-direction: column;
}

.book-filter-tabs {
    display: flex;
    justify-content: center;
    margin: 20px auto;
}

.book-filter-tab {
    background-color: var(--light-bg);
    color: var(--light-primary);
    border: none;
    padding: 8px 16px;
    margin: 0 5px;
    border: 0.1rem solid var(--light-primary);
    border-radius: 5px;
    cursor: pointer;
}

.book-filter-tab.active {
    background-color: var(--dark-bg);
    color: var(--dark-primary);
}

.shelf-title {
    font-size: 1.5rem;
    color: var(--light-primary);
    margin: 1rem;
}

.shelf {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    padding: 1rem 8rem 1rem 2rem;
    width: 90vw;
    height: 22rem;
    margin: 0 auto;
    margin-top: 32px;
    overflow-x: auto;
}

.book {
    width: 4.5rem;
    height: 17rem;
    position: relative;
    margin-left: 1px;
    transform-style: preserve-3d;
    transform: translateZ(0) rotateY(0);
    transition: transform 1s;
}

.side {
    position: absolute;
    border: 0.1rem solid black;
    border-radius: 3px;
    font-weight: bold;
    color: black;
    text-align: center;
    transform-origin: center left;
}

.spine {
    position: relative;
    width: 4.5rem;
    height: 17rem;
    background-color: rgba(0, 0, 0, 0.8);
    transform: rotateY(0deg) translateZ(0px);
    padding: 0.5rem;
}

.spine-title {
    margin: 4px;
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 1.2rem;
    color: var(--dark-primary);
    writing-mode: vertical-rl;
    text-orientation: mixed;
}

.spine-author {
    position: absolute;
    color: goldenrod;
    bottom: 0px;
    left: 20%;
}

.top {
    width: 4.5rem;
    height: 190px;
    top: -2px;
    background-image: linear-gradient(90deg, white 90%, gray 10%);
    background-size: 5px 5px;
    transform: rotateX(90deg) translateZ(95px) translateY(-95px);
}

.goodreads-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
}

.cover {
    width: 190px;
    height: 17rem;
    top: 0px;
    background-size: contain;
    background-repeat: round;
    left: 4.5rem;
    transform: rotateY(90deg) translateZ(0);
    transition: transform 1s;
}

.book:hover {
    z-index: 1;
    transform: rotateX(-25deg) rotateY(-40deg) rotateZ(-15deg) translateY(50px) translateX(-30px);
}

@media screen and (max-width: 600px) {
    .shelf {
        width: 94vw;
        margin: 0 auto 32px auto;
    }

    .book-filter-tabs {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
    }
}