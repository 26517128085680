.form-container>form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 8rem;
    background-image: url('../../Components/Images/cerberus.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    padding: 0;
    margin: 0;
    color: var(--dark-primary);
}

.form-container>h1 {
    text-align: center;
}