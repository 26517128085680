.poem-card-container {
    min-width: 17rem;
}

.poem-card {
    margin-bottom: 0.8rem;
    padding: 16px 20px;
    border: 0.1rem solid var(--light-primary);
    border-radius: 0.5rem;
}

.poem-card-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.6rem;
}

.filled {
    position: relative;
    width: 100%;
    height: 0.12rem;
    margin: 0.4rem auto;
    background: var(--dark-bg);
}

.poem-card-text {
    line-height: 1.23;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0.2rem;
}

pre {
    font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
    font-size: 0.96rem;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
}

.poem-card-text::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 600px) {
    .poem-card {
        margin-bottom: 0.8rem;
        padding: 20px 18px;
    }

    pre {
        font-size: 1rem;
    }
}