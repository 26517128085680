.about {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 8rem);
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin: 1rem auto;
    border: 0.1rem solid var(--light-primary);
}

.about-name {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1.6rem;
}

.about-desc {
    font-size: 1.4rem;
    font-weight: 500;
}

.socials {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2.4rem;
    padding-top: 1rem;
}

.socials a svg {
    color: var(--light-primary);
    scale: 1.8;
}

@media screen and (max-width: 768px) {
    .info {
        width: 92%;
        padding: 1rem;
    }

    .about-name {
        font-size: 1.5rem;
    }

    .about-desc {
        font-size: 1.2rem;
        font-weight: 500;
    }

    .socials {
        grid-gap: 2rem;
    }
}
