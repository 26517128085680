.footer-container {
    position: relative;
    bottom: 0;
    color: var(--dark-primary);
    text-align: center;
    height: 3rem;
    padding: 1rem 0;
    grid-column: 1 / -1;
    width: 100vw;
    background-color: var(--dark-bg);
}

.footer-text {
    margin: 0;
    font-size: 1rem;
    letter-spacing: 0.5px;
}